import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Wordlist from "../components/wordlist"
import SEO from "../components/seo"

export default ({ data }) => {
  const node = data.allGoogleSpreadsheetListList.edges[0].node
  const randomWords = data.others.edges
    .sort(function() {
      return 0.5 - Math.random()
    })
    .slice(0, 5)

  return (
    <Layout>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "DefinedTerm",
          name: node.word,
          description: node.meaning,
        })}
      </script>
      <SEO
        title={node.word}
        description={`${node.word} bedeutet im Appenzeller Dialekt "${
          node.meaning
        }"`}
        ogImage={`/images/words/${node.slug}.png`}
      />
      <h1
        style={{
          fontSize: `3rem`,
          marginTop: `2rem `,
        }}
      >
        »{node.word}«
      </h1>
      <p
        style={{
          fontSize: `1.5rem`,
          lineHeight: `1.2`,
        }}
      >
        {node.meaning}
      </p>
      <h3
        style={{
          marginTop: `5rem`,
        }}
      >
        Weitere Begriffe:
      </h3>
      <Wordlist words={randomWords} />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allGoogleSpreadsheetListList(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          word
          meaning
          slug
        }
      }
    }
    others: allGoogleSpreadsheetListList(filter: { slug: { ne: $slug } }) {
      edges {
        node {
          word
          slug
        }
      }
    }
  }
`
