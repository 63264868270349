import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Wordlist = ({ words }) => (
  <ul
    style={{
      listStyleType: `none`,
      marginLeft: 0,
      lineHeight: 2,
    }}
  >
    {words.map(({ node }) => (
      <li key={node.slug}>
        <Link
          to={`/${node.slug}`}
          style={{
            paddingRight: `1.5rem`,
            color: `#e30513`,
            fontSize: `1.4rem`,
            float: `left`,
          }}
        >
          {node.word}
        </Link>
      </li>
    ))}
  </ul>
)

Wordlist.propTypes = {
  words: PropTypes.array.isRequired,
}

export default Wordlist
