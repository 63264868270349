import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header style={{ marginBottom: `2rem` }}>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 800,
        padding: `2rem 1rem`,
      }}
    >
      <h1 style={{ margin: 0, marginBottom: `2rem`, float: `left` }}>
        <Link
          to="/"
          style={{
            color: `#e30513`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div
        className={`social-icons`}
        style={{
          float: `right`,
          marginLeft: `1rem`,
          marginBottom: `2rem`,
        }}
      >
        <a
          href={`https://instagram.com/appenzellerdialekt.ch`}
          target={`_blank`}
        >
          <img
            style={{
              marginRight: `1rem`,
              minWidth: `40px`,
              width: `40px`,
              verticalAlign: `middle`,
            }}
            src={require(`../images/instagram.png`)}
            alt={`Zur Instagram-Seite`}
          />
        </a>
        <a
          href={`https://www.facebook.com/appenzellerdialekt`}
          target={`_blank`}
        >
          <img
            style={{
              minWidth: `40px`,
              width: `40px`,
              verticalAlign: `middle`,
            }}
            src={require(`../images/facebook.png`)}
            alt={`Zur Facebook-Seite`}
            target={`_blank`}
          />
        </a>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
